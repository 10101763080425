import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import React from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, Text, Platform } from 'react-native';

import { Colors, Typography } from '../constants';

import { connect } from 'react-redux';
import { createTask } from '../ducks/TasksDuck';
import { updateUser } from '../ducks/UserSessionDuck';

class CreateTaskScreen extends React.Component {
    state = {
        message: '',
    };

    async componentDidMount() {
        await this.registerForPushNotificationsAsync();
    }

    async registerForPushNotificationsAsync() {
        let token;
        if (Device && Device.isDevice && Platform && (Platform.OS === 'android' || Platform.OS === 'ios')) {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                alert('Failed to get push token for push notification!');
                return;
            }
            token = (await Notifications.getExpoPushTokenAsync()).data;
            console.log(token);
        } else {
            alert('Must use physical device for Push Notifications');
        }

        if (Platform && Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        if (token) {
            let newUser = {};
            newUser.expo_token = token;
            this.props.updateUser({
                user: newUser,
            });
        }
        // return token;
    }

    async schedulePushNotification() {
        await Notifications.setNotificationChannelAsync('task', {
            name: 'Task notifications',
            sound: 'just-do-it.wav', // Provide ONLY the base filename
        });

        await Notifications.scheduleNotificationAsync({
            content: {
                title: 'Heyo!',
                body: 'Pls wrk3',
                sound: 'just-do-it.wav',
                data: { data: 'goes here' },
                vibrate: false,
            },
            trigger: { seconds: 4, channelId: 'task' },
        });
    }

    handleSubmit = () => {
        this.props.createTask({
            message: this.state.message,
        });
    };

    render() {
        return (
            <View style={styles.container}>
                <TextInput
                    style={styles.inputBox}
                    value={this.state.message}
                    onChangeText={(message) => this.setState({ message })}
                    placeholder='Message'
                    placeholderTextColor={Colors.placeholderText}
                />
                <TouchableOpacity style={styles.button} onPress={this.handleSubmit}>
                    <Text style={styles.buttonText}>Create Task</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={this.schedulePushNotification}>
                    <Text style={styles.buttonText}>Press Me!</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'center',
        paddingTop: 30,
    },
    inputBox: {
        ...Typography.subtitle1,
        width: '85%',
        margin: 10,
        padding: 15,
        borderColor: Colors.blueSapphire,
        borderBottomWidth: 1,
        textAlign: 'center',
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
});

const mapStateToProps = ({ tasks }) => {
    const { createTaskSuccess, error, loading } = tasks;

    return {
        createTaskSuccess,
        error,
        loading,
    };
};

export default connect(mapStateToProps, {
    createTask,
    updateUser,
})(CreateTaskScreen);
