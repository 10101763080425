import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Asset } from 'expo-asset';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import * as Notifications from 'expo-notifications';

import Navigation from './navigation';
import { store } from './store';
import Firebase from './config/Firebase.js';

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
    firebase: Firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance: createFirestoreInstance,
};

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

export default class App extends React.Component {
    state = {
        isReady: false,
    };

    componentDidMount() {
        // for notifications opened with the app in the foreground
        // handle as toast
        this._notificationSubscription = Notifications.addNotificationReceivedListener((notification) => {
            this._handleNotification(notification);
        });

        // for notifications opened outside the app with the app open
        // THIS IS STILL FIRING WHEN THE APP IS OPEN IN THE FOREGROUND
        this._responseListener = Notifications.addNotificationResponseReceivedListener(({ notification }) => {
            this._handlePushAsDeeplink(notification.request.content.data);
        });

        // for notifications opened outside the app with the app closed
        if (this.props.exp?.notification?.data) {
            const notificationData = Object.assign(this.props.exp?.notification?.data);
            this._handlePushAsDeeplink(notificationData);
        }
    }

    componentWillUnmount = () => {
        Notifications.removeNotificationSubscription(this._notificationSubscription);
        Notifications.removeNotificationSubscription(this._responseListener);
    };

    _handleNotification = (notification) => {
        const notificationData = notification.request.content.data;

        if (!!notificationData && !!store) {
            console.log('notificationData', notificationData);
        }
    };

    _handlePushAsDeeplink = (notification) => {
        console.log('_handlePushAsDeeplink notification', notification);
        if (!!notification?.deeplink_data) {
            // const deeplinkData = {
            //     deeplink_data: notification.deeplink_data,
            //     deeplink_trigger_type: notification.deeplink_trigger_type,
            //     canonicalIdentifier: notification.deeplink_trigger_type,
            // };
            // if (deeplinkData.deeplink_trigger_type && deeplinkData.deeplink_data) {
            //     const user = store.getState().userSession?.user;
            //     if (user) {
            //         store.dispatch(
            //             releaseDeeplinkData({
            //                 deeplinkData,
            //                 user,
            //             })
            //         );
            //     } else {
            //         store.dispatch(saveDeeplinkData({ deeplinkData, canonicalIdentifier: deeplinkData?.canonicalIdentifier }));
            //     }
            // }
        }
    };

    render() {
        if (!this.state.isReady) {
            return <AppLoading startAsync={this._cacheResourcesAsync} onFinish={() => this.setState({ isReady: true })} onError={console.warn} />;
        }

        return (
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <SafeAreaProvider>
                        <Navigation />
                        <StatusBar />
                    </SafeAreaProvider>
                </ReactReduxFirebaseProvider>
            </Provider>
        );
    }

    async _cacheResourcesAsync() {
        await Font.loadAsync({
            ...Ionicons.font,
            metropolisBlack: require('./assets/fonts/Metropolis-Black.otf'),
            metropolisBlackItalic: require('./assets/fonts/Metropolis-BlackItalic.otf'),
            metropolisBold: require('./assets/fonts/Metropolis-Bold.otf'),
            metropolisBoldItalic: require('./assets/fonts/Metropolis-BoldItalic.otf'),
            metropolisExtraBold: require('./assets/fonts/Metropolis-ExtraBold.otf'),
            metropolisExtraBoldItalic: require('./assets/fonts/Metropolis-ExtraBoldItalic.otf'),
            metropolisExtraLight: require('./assets/fonts/Metropolis-ExtraLight.otf'),
            metropolisExtraLightItalic: require('./assets/fonts/Metropolis-ExtraLightItalic.otf'),
            metropolisLight: require('./assets/fonts/Metropolis-Light.otf'),
            metropolisLightItalic: require('./assets/fonts/Metropolis-LightItalic.otf'),
            metropolisMedium: require('./assets/fonts/Metropolis-Medium.otf'),
            metropolisMediumItalic: require('./assets/fonts/Metropolis-MediumItalic.otf'),
            metropolisRegular: require('./assets/fonts/Metropolis-Regular.otf'),
            metropolisRegularItalic: require('./assets/fonts/Metropolis-RegularItalic.otf'),
            metropolisSemiBold: require('./assets/fonts/Metropolis-SemiBold.otf'),
            metropolisSemiBoldItalic: require('./assets/fonts/Metropolis-SemiBoldItalic.otf'),
            metropolisThin: require('./assets/fonts/Metropolis-Thin.otf'),
            metropolisThinItalic: require('./assets/fonts/Metropolis-ThinItalic.otf'),
        });

        const images = [require('./assets/images/icon.png')];

        const cacheImages = images.map((image) => {
            return Asset.fromModule(image).downloadAsync();
        });

        return Promise.all(cacheImages);
    }
}
