import Firebase, { db } from '../config/Firebase.js';
import * as NavigationHelper from '../navigation/NavigationHelper';
// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_FREN: 'CREATE_FREN',
    CREATE_FREN_SUCCESS: 'CREATE_FREN_SUCCESS',
    CREATE_FREN_FAIL: 'CREATE_FREN_FAIL',
    UPDATE_FREN: 'UPDATE_FREN',
    UPDATE_FREN_SUCCESS: 'UPDATE_FREN_SUCCESS',
    UPDATE_FREN_FAIL: 'UPDATE_FREN_FAIL',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    createFrenSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_FREN:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_FREN_SUCCESS:
            return {
                ...state,
                loading: false,
                createFrenSuccess: true,
            };
        case types.CREATE_FREN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_FREN:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_FREN_SUCCESS:
            return {
                ...state,
                loading: false,
                updateFrenSuccess: true,
            };
        case types.UPDATE_FREN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const createFren = (fren) => {
    return (dispatch) => {
        try {
            let frenObject = Object.assign({}, fren);
            frenObject.date = new Date();
            const ref = db.collection('frens').doc();
            const id = ref.id;

            frenObject.id = id;
            dispatch({ type: types.CREATE_FREN, payload: frenObject });

            db.collection('frens')
                .doc(`${id}`)
                .set(frenObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_FREN_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_FREN_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_FREN_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const updateFren = (ref, fren) => {
    return (dispatch) => {
        try {
            let frenObject = Object.assign({}, fren);
            dispatch({ type: types.UPDATE_FREN, payload: frenObject });
            db.collection('frens')
                .doc(ref.doc)
                .update(frenObject)
                .then((ref) => {
                    dispatch({
                        type: types.UPDATE_FREN_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.UPDATE_FREN_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.UPDATE_FREN_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};
