import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { SwipeListView } from 'react-native-swipe-list-view';

import { updateUser, updateComboLevel } from '../ducks/UserSessionDuck';
import { updateTask, completeTask, missTask, saveClickedTask } from '../ducks/TasksDuck';
import { TaskItem } from '../components';
import { Colors, Typography } from '../constants';
// import audio from '../assets/audio/victory.mp3';
// import justDoItAudio from '../assets/audio/just-do-it.wav';
// import yesYouCanAudio from '../assets/audio/yes-you-can.wav';
import { Audio } from 'expo-av';
import { getComboSoundFile } from '../helpers/SoundHelper';

class TasksScreen extends Component {
    state = {
        isFetching: false,
        sound: null,
    };

    renderHeader = () => {
        return <View style={{ height: 8 }} />;
    };

    renderListEmpty = () => {
        return <View>{/* <Text>empty tho</Text> */}</View>;
    };

    playCharacterSound = async ({ character, comboLevel }) => {
        const soundFileName = getComboSoundFile({ character, comboLevel });
        const { sound } = await Audio.Sound.createAsync(require(`../assets/audio/${soundFileName}`));
        this.setState({
            sound: sound,
        });
        await sound.playAsync();
    };

    // playAudio = async () => {
    //     const { sound } = await Audio.Sound.createAsync(require('../assets/audio/victory.mp3'));
    //     this.setState({
    //         sound: sound,
    //     });
    //     console.log('Playing Sound');
    //     await sound.playAsync();
    //     // new Audio(audio).play();
    // };

    // playJustDoItAudio = async () => {
    //     const { sound } = await Audio.Sound.createAsync(require('../assets/audio/just-do-it.wav'));
    //     this.setState({
    //         sound: sound,
    //     });
    //     console.log('Playing Sound');
    //     await sound.playAsync();
    //     // new Audio(justDoItAudio).play();
    // };

    // playYesYouCanAudio = () => {
    //     new Audio(yesYouCanAudio).play();
    // };

    setNavigationOptions = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('CreateTaskScreen')}>
                    <Text style={{ ...Typography.button }}>Create New</Text>
                </TouchableOpacity>
            ),
        });
        // const { navigation } = this.props;
        // navigation.setOptions({
        //     headerRight: () => (
        //         <TouchableOpacity onPress={() => navigation.navigate('CreateTaskScreen')}>
        //             <Text style={{ ...Typography.button }}>Create New</Text>
        //         </TouchableOpacity>
        //     ),
        // });
    };

    componentDidMount = () => {
        this.setNavigationOptions();
    };

    componentDidUpdate = () => {
        this.setNavigationOptions();
    };

    render() {
        const { profile } = this.props;
        return (
            <View style={styles.container}>
                <SwipeListView
                    data={this.props.tasks}
                    renderItem={({ item }, rowMap) => {
                        if (item.completionDate) {
                            return (
                                <View
                                    style={{
                                        height: 50,
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            ...Typography.subtitle1,
                                            fontSize: 24,
                                            color: Colors.blueSapphire,
                                        }}
                                    >
                                        COMPLETED
                                    </Text>
                                </View>
                            );
                        } else if (item.missDate) {
                            return (
                                <View
                                    style={{
                                        height: 50,
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            ...Typography.subtitle1,
                                            fontSize: 24,
                                            color: Colors.rubyRed,
                                        }}
                                    >
                                        MISSED
                                    </Text>
                                </View>
                            );
                        } else {
                            return (
                                <TaskItem
                                    onPress={() => this._onTaskItemPress(item)}
                                    task={item}
                                    // onComplete={() => {
                                    //     this.props.completeTask(item.id);
                                    //     console.log('onComplete');
                                    //     this.playAudio();
                                    // }}
                                    // onMiss={() => {
                                    //     this.playYesYouCanAudio();
                                    //     this.props.missTask(item.id);
                                    // }}
                                    isFirst={this.props.tasks[0].id === item.id}
                                />
                            );
                        }
                    }}
                    renderHiddenItem={({ item }, rowMap) => {
                        if (!item.completionDate && !item.missDate) {
                            return (
                                <View style={styles.rowBack}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.completeTask(
                                                {
                                                    collection: 'tasks',
                                                    doc: item.id,
                                                },
                                                { completionDate: new Date() }
                                            );
                                            this.playCharacterSound({
                                                character: profile.selectedCharacter,
                                                comboLevel: profile.comboLevel ? profile.comboLevel : 1,
                                            });
                                            this.props.updateComboLevel({ comboType: 'complete', user: profile });
                                            // this.playAudio();
                                        }}
                                    >
                                        <Text
                                            style={{
                                                ...Typography.subtitle1,
                                                fontSize: 24,
                                                color: Colors.blueSapphire,
                                            }}
                                        >
                                            Done
                                        </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            // this.playJustDoItAudio();
                                            this.props.missTask(
                                                {
                                                    collection: 'tasks',
                                                    doc: item.id,
                                                },
                                                { missDate: new Date() }
                                            );
                                            this.playCharacterSound({ character: profile.selectedCharacter, comboLevel: 0 });
                                            this.props.updateComboLevel({ comboType: 'miss', user: profile });
                                        }}
                                    >
                                        <Text
                                            style={{
                                                ...Typography.subtitle1,
                                                fontSize: 24,
                                                color: Colors.rubyRed,
                                            }}
                                        >
                                            Miss
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            );
                        } else {
                            return null;
                        }
                    }}
                    leftOpenValue={90}
                    rightOpenValue={-75}
                />
                <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}></ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    // contentContainer: {
    //     paddingTop: 30,
    // },
    backTextWhite: {
        color: '#FFF',
    },
    rowFront: {
        alignItems: 'center',
        backgroundColor: '#CCC',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        justifyContent: 'center',
        height: 50,
    },
    rowBack: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: 'red',
        right: 0,
    },
});

const mapStateToProps = ({ firestore, firebase }) => {
    const { ordered } = firestore;
    const { auth, profile } = firebase;

    return {
        tasks: ordered.tasks ? ordered.tasks : [],
        auth,
        profile,
    };
};

export default compose(
    connect(mapStateToProps, {
        updateUser,
        updateComboLevel,
        updateTask,
        completeTask,
        missTask,
        saveClickedTask,
    }),
    firestoreConnect((props) => {
        return [
            {
                collection: 'tasks',
                where: ['uid', '==', props.auth.uid],
                orderBy: ['date', 'desc'],
            },
        ];
    })
)(TasksScreen);
