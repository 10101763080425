import React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Colors, Typography } from '../../constants';

const styles = StyleSheet.create({
    subtitle: {
        ...Typography.subtitle1,
        color: Colors.white,
    },
});

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const weeks = Math.floor(countDown / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24)) - weeks * 7;
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [weeks, days, hours, minutes, seconds];
};

const ShowCounter = ({ weeks, days, hours, minutes, seconds }) => {
    return (
        <View
            style={{
                marginTop: 50,
                flex: 1,
                marginHorizontal: 24,
                backgroundColor: Colors.blueSapphire,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text style={styles.subtitle}>{weeks + 'w ' + days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '}</Text>
        </View>
    );
};

export const CountdownTimer = ({ targetDate }) => {
    const [weeks, days, hours, minutes, seconds] = useCountdown(targetDate);

    if (weeks * 7 + days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return <ShowCounter weeks={weeks} days={days} hours={hours} minutes={minutes} seconds={seconds} />;
    }
};
