import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, View, ScrollView, TouchableOpacity, Text } from 'react-native';
import { firestoreConnect } from 'react-redux-firebase';

import { Colors, Typography } from '../constants';
import { CountdownTimer } from '../components/common/CountDown';
const countDownDate = new Date('May 31, 2023 23:59:59').getTime();

class SettingsScreen extends Component {
    render() {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
                    <TouchableOpacity
                        onPress={() => {
                            this.props.firebase.logout();
                        }}
                    >
                        <View
                            style={{
                                height: 50,
                                flex: 1,
                                marginHorizontal: 24,
                                backgroundColor: Colors.blueSapphire,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={styles.subtitle}>Logout</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('ChooseYourCharacterScreen');
                        }}
                    >
                        <View
                            style={{
                                marginTop: 50,
                                height: 50,
                                flex: 1,
                                marginHorizontal: 24,
                                backgroundColor: Colors.blueSapphire,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={styles.subtitle}>Choose your character</Text>
                        </View>
                    </TouchableOpacity>
                    <CountdownTimer targetDate={countDownDate} />
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    contentContainer: {
        paddingTop: 16,
    },
    subtitle: {
        ...Typography.subtitle1,
        color: Colors.white,
    },
});

const mapStateToProps = ({ firebase }) => {
    const { auth, profile } = firebase;

    return {
        auth,
        profile,
    };
};

export default compose(connect(mapStateToProps, {}), firestoreConnect())(SettingsScreen);
