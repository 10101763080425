import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, FlatList, TouchableOpacity, Text } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { updateUser } from '../ducks/UserSessionDuck';
import { updateHabit, saveClickedHabit, incrementHabitActivity } from '../ducks/HabitsDuck';
import { HabitCalendar, HabitItem } from '../components';
import { Colors, Typography } from '../constants';

class HomeScreen extends Component {
    state = {
        isFetching: false,
    };

    _renderHeader = () => {
        return <View style={{ height: 8 }} />;
    };

    _renderListEmpty = () => {
        return <View>{/* <Text>empty tho</Text> */}</View>;
    };

    componentDidMount = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('CreateHabitScreen')}>
                    <Text style={{ ...Typography.button }}>Create New</Text>
                </TouchableOpacity>
            ),
        });
    };

    componentDidUpdate = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('CreateHabitScreen')}>
                    <Text style={{ ...Typography.button }}>Create New</Text>
                </TouchableOpacity>
            ),
        });
    };

    render() {
        console.log('this.props', this.props);
        const { incrementHabitActivity, habitActivity } = this.props;
        return (
            <View style={styles.container}>
                <FlatList
                    data={this.props.habits}
                    renderItem={({ item }) => {
                        const habitActivityArr = habitActivity.filter(({ habitId }) => habitId === item.id);
                        return (
                            <HabitCalendar habit={item} habitActivityData={habitActivityArr} />
                            // <HabitItem
                            //     onPress={() => this._onHabitItemPress(item)}
                            //     habit={item}
                            //     onComplete={() => {
                            //         this.props.completeHabit(item.id);
                            //     }}
                            //     onMiss={() => {
                            //         this.props.missHabit(item.id);
                            //     }}
                            //     isFirst={this.props.habits[0].id === item.id}
                            // />
                        );
                    }}
                    backgroundColor={Colors.mediumChampagne}
                    keyExtractor={(item) => `${item.id}`}
                    refreshing={this.state.isFetching}
                    onRefresh={this._onRefresh}
                    ListHeaderComponent={this._renderHeader}
                    ListFooterComponent={this._renderFooter}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.1}
                    ListEmptyComponent={this._renderListEmpty}
                    onScroll={this.handleScroll}
                    ref={(ref) => {
                        this._flatList = ref;
                    }}
                />
                <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}></ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    contentContainer: {
        paddingTop: 30,
    },
});

const mapStateToProps = ({ firestore, firebase }) => {
    const { ordered } = firestore;
    const { auth, profile } = firebase;

    return {
        habits: ordered.habits ? ordered.habits : [],
        habitActivity: ordered.habitActivity ? ordered.habitActivity : [],
        auth,
        profile,
    };
};

export default compose(
    connect(mapStateToProps, {
        updateUser,
        updateHabit,
        saveClickedHabit,
        incrementHabitActivity,
    }),
    firestoreConnect((props) => [
        {
            collection: 'habits',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['title', 'desc'],
        },
        {
            collection: 'habitActivity',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['date', 'desc'],
        },
    ])
)(HomeScreen);
