import { db } from '../config/Firebase.js';
import * as NavigationHelper from '../navigation/NavigationHelper';
// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_SYSTEM: 'CREATE_SYSTEM',
    CREATE_SYSTEM_SUCCESS: 'CREATE_SYSTEM_SUCCESS',
    CREATE_SYSTEM_FAIL: 'CREATE_SYSTEM_FAIL',
    UPDATE_SYSTEM: 'UPDATE_SYSTEM',
    UPDATE_SYSTEM_SUCCESS: 'UPDATE_SYSTEM_SUCCESS',
    UPDATE_SYSTEM_FAIL: 'UPDATE_SYSTEM_FAIL',
    SAVE_CLICKED_SYSTEM: 'SAVE_CLICKED_SYSTEM',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    createSystemSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_SYSTEM:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SYSTEM_SUCCESS:
            return {
                ...state,
                loading: false,
                createSystemSuccess: true,
            };
        case types.CREATE_SYSTEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_SYSTEM:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SYSTEM_SUCCESS:
            return {
                ...state,
                loading: false,
                updateSystemSuccess: true,
            };
        case types.UPDATE_SYSTEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SAVE_CLICKED_SYSTEM:
            return {
                ...state,
                clickedSystemId: action.payload,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const createSystem = (system) => {
    return (dispatch) => {
        try {
            let systemObject = Object.assign({}, system);
            systemObject.date = new Date();
            const ref = db.collection('systems').doc();
            const id = ref.id;

            systemObject.id = id;
            dispatch({ type: types.CREATE_SYSTEM, payload: systemObject });

            db.collection('systems')
                .doc(`${id}`)
                .set(systemObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_SYSTEM_SUCCESS,
                        payload: ref,
                    });

                    NavigationHelper.navigate('SettingsScreen');
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_SYSTEM_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_SYSTEM_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const updateSystem = (ref, system) => {
    return (dispatch) => {
        try {
            let systemObject = Object.assign({}, system);
            dispatch({ type: types.UPDATE_SYSTEM, payload: systemObject });
            db.collection('systems')
                .doc(ref.doc)
                .update(systemObject)
                .then((ref) => {
                    dispatch({
                        type: types.UPDATE_SYSTEM_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.UPDATE_SYSTEM_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.UPDATE_SYSTEM_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const saveClickedSystem = (systemId) => {
    return (dispatch) => {
        dispatch({ type: types.SAVE_CLICKED_SYSTEM, payload: systemId });
    };
};
