import { Audio } from 'expo-av';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Picker } from 'react-native';

import { connect } from 'react-redux';
import CharacterSoundMap from '../constants/CharacterSoundMap';
import GlobalStyles from '../constants/GlobalStyles';
import { updateUser } from '../ducks/UserSessionDuck';
import { getComboSoundFile } from '../helpers/SoundHelper';

const { characters } = CharacterSoundMap;

const styles = {
    ...GlobalStyles,
};

class ChooseYourCharacterScreen extends Component {
    state = {
        selectedCharacter: null,
    };

    componentDidMount() {}

    changeCharacter = async () => {
        const { selectedCharacter } = this.state;
        const { updateUser } = this.props;
        await this.playCharacterSound({ character: selectedCharacter, comboLevel: 8 });
        updateUser({
            user: { selectedCharacter, comboLevel: 1 },
        });
    };

    playCharacterSound = async ({ character, comboLevel }) => {
        const soundFileName = getComboSoundFile({ character, comboLevel });
        const { sound } = await Audio.Sound.createAsync(require(`../assets/audio/${soundFileName}`));
        this.setState({
            sound: sound,
        });
        await sound.playAsync();
    };

    renderChooseCharacterForm = () => {
        const { selectedCharacter } = this.state;
        return (
            <View style={styles.container}>
                <div style={styles.height40} />
                {characters?.length > 0 && (
                    <Picker
                        selectedValue={selectedCharacter}
                        style={{ height: 50, marginHorizontal: 40 }}
                        onValueChange={async (itemValue) => {
                            await this.playCharacterSound({ character: itemValue, comboLevel: null });
                            this.setState({ selectedCharacter: itemValue });
                        }}
                    >
                        {characters.map((role) => {
                            return <Picker.Item label={role} value={role} />;
                        })}
                    </Picker>
                )}
                <TouchableOpacity style={styles.button} onPress={this.changeCharacter}>
                    <Text style={styles.buttonText}>Change</Text>
                </TouchableOpacity>
            </View>
        );
    };

    render() {
        const { classes } = this.props;

        return <div style={styles.background}>{this.renderChooseCharacterForm()}</div>;
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, { updateUser })(ChooseYourCharacterScreen);
