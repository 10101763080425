import { combineReducers } from 'redux';

import AgendaReducer from './ducks/AgendaDuck';
import UserSessionReducer from './ducks/UserSessionDuck';
import HabitsReducer from './ducks/HabitsDuck';
import HabitSystemsReducer from './ducks/HabitSystemsDuck';
import TasksReducer from './ducks/TasksDuck';

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

export default combineReducers({
    agenda: AgendaReducer,
    userSession: UserSessionReducer,
    habits: HabitsReducer,
    habitSystems: HabitSystemsReducer,
    tasks: TasksReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});
