import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, FlatList, TouchableOpacity, Text } from 'react-native';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Audio } from 'expo-av';

import { completeGoal, missGoal } from '../ducks/AgendaDuck';
import { HabitItem, GoalItem, WeekPicker } from '../components';
import { Colors, Typography } from '../constants';
import { SwipeListView } from 'react-native-swipe-list-view';

class AgendaScreen extends Component {
    state = {
        isFetching: false,
        activeWeek: moment().clone().startOf('isoWeek'),
    };

    playAudio = async () => {
        const { sound } = await Audio.Sound.createAsync(require('../assets/audio/yes-you-can.wav'));
        this.setState({
            sound: sound,
        });
        console.log('Playing Sound');
        await sound.playAsync();
        // new Audio(audio).play();
    };

    playJustDoItAudio = async () => {
        const { sound } = await Audio.Sound.createAsync(require('../assets/audio/just-do-it.wav'));
        this.setState({
            sound: sound,
        });
        console.log('Playing Sound');
        await sound.playAsync();
        // new Audio(justDoItAudio).play();
    };

    componentDidMount = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('CreateAgendaScreen')}>
                    <Text style={{ ...Typography.button }}>Create New</Text>
                </TouchableOpacity>
            ),
        });
    };

    componentDidUpdate = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('CreateAgendaScreen')}>
                    <Text style={{ ...Typography.button }}>Create New</Text>
                </TouchableOpacity>
            ),
        });
    };

    render() {
        const { activeWeek } = this.state;
        const { goals } = this.props;
        const standardizedActiveWeek = `${activeWeek.format('YYYY-MM-DDT00:00:00')}+00:00`;
        const activeWeekGoals = goals.filter((goal) => goal.week === standardizedActiveWeek);

        return (
            <View style={styles.container}>
                <WeekPicker
                    activeWeek={activeWeek}
                    setActiveWeek={(activeWeek) => {
                        this.setState({ activeWeek });
                    }}
                />
                <SwipeListView
                    data={activeWeekGoals}
                    renderItem={({ item }, rowMap) => {
                        return <GoalItem goal={item} />;
                    }}
                    renderHiddenItem={({ item }, rowMap) => {
                        if (!item.completionDate && !item.missDate) {
                            return (
                                <View style={styles.rowBack}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.completeGoal(
                                                {
                                                    collection: 'goals',
                                                    doc: item.id,
                                                },
                                                { completionDate: new Date() }
                                            );
                                            console.log('onComplete');
                                            this.playAudio();
                                        }}
                                    >
                                        <Text
                                            style={{
                                                ...Typography.subtitle1,
                                                fontSize: 24,
                                                color: Colors.blueSapphire,
                                            }}
                                        >
                                            Done
                                        </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.playJustDoItAudio();
                                            this.props.missGoal(
                                                {
                                                    collection: 'goals',
                                                    doc: item.id,
                                                },
                                                { missDate: new Date() }
                                            );
                                        }}
                                    >
                                        <Text
                                            style={{
                                                ...Typography.subtitle1,
                                                fontSize: 24,
                                                color: Colors.rubyRed,
                                            }}
                                        >
                                            Miss
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            );
                        } else {
                            return null;
                        }
                    }}
                    leftOpenValue={90}
                    rightOpenValue={-75}
                />
                {/* <FlatList
                    data={this.props.habits}
                    renderItem={({ item }) => {
                        return (
                            <HabitItem
                                onPress={() => this._onHabitItemPress(item)}
                                habit={item}
                                onComplete={() => {
                                    this.props.completeHabit(item.id);
                                }}
                                onMiss={() => {
                                    this.props.missHabit(item.id);
                                }}
                                isFirst={this.props.habits[0].id === item.id}
                            />
                        );
                    }}
                    backgroundColor={Colors.mediumChampagne}
                    keyExtractor={(item) => `${item.id}`}
                    refreshing={this.state.isFetching}
                    onRefresh={this._onRefresh}
                    ListHeaderComponent={this._renderHeader}
                    ListFooterComponent={this._renderFooter}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.1}
                    ListEmptyComponent={this._renderListEmpty}
                    onScroll={this.handleScroll}
                    ref={(ref) => {
                        this._flatList = ref;
                    }}
                /> */}
                {/* <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}></ScrollView> */}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    contentContainer: {
        paddingTop: 30,
    },
    rowBack: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
    },
});

const mapStateToProps = ({ firestore, firebase }) => {
    const { ordered } = firestore;
    const { auth } = firebase;

    return {
        auth,
        roles: ordered.roles ? ordered.roles : [],
        goals: ordered.goals ? ordered.goals : [],
    };
};

export default compose(
    connect(mapStateToProps, {
        completeGoal,
        missGoal,
    }),
    firestoreConnect((props) => [
        {
            collection: 'goals',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['week', 'desc'],
        },
        {
            collection: 'roles',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['date', 'desc'],
        },
    ])
)(AgendaScreen);
