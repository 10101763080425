import CharacterSoundMap from '../constants/CharacterSoundMap';

export const getComboSoundFile = ({ character, comboLevel }) => {
    const sounds = CharacterSoundMap[character ? character : 'Shia'];
    switch (comboLevel) {
        case 0:
            return sounds['miss'];
        case 1:
            return sounds['one'];
        case 2:
            return sounds['two'];
        case 3:
            return sounds['three'];
        case 4:
            return sounds['four'];
        case 5:
            return sounds['five'];
        case 6:
            return sounds['six'];
        case 7:
            return sounds['max'];
        case 8:
            return sounds['intro2'];
        default:
            return sounds['intro'];
    }
};
