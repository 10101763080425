import Firebase, { db } from '../config/Firebase.js';
import * as NavigationHelper from '../navigation/NavigationHelper';
// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_ROLE: 'CREATE_ROLE',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAIL: 'CREATE_ROLE_FAIL',
    CREATE_GOAL: 'CREATE_GOAL',
    CREATE_GOAL_SUCCESS: 'CREATE_GOAL_SUCCESS',
    CREATE_GOAL_FAIL: 'CREATE_GOAL_FAIL',
    COMPLETE_GOAL: 'COMPLETE_GOAL',
    COMPLETE_GOAL_SUCCESS: 'COMPLETE_GOAL_SUCCESS',
    COMPLETE_GOAL_FAIL: 'COMPLETE_GOAL_FAIL',
    MISS_GOAL: 'MISS_GOAL',
    MISS_GOAL_SUCCESS: 'MISS_GOAL_SUCCESS',
    MISS_GOAL_FAIL: 'MISS_GOAL_FAIL',
    CREATE_AGENDA: 'CREATE_AGENDA',
    CREATE_AGENDA_SUCCESS: 'CREATE_AGENDA_SUCCESS',
    CREATE_AGENDA_FAIL: 'CREATE_AGENDA_FAIL',
    UPDATE_AGENDA: 'UPDATE_AGENDA',
    UPDATE_AGENDA_SUCCESS: 'UPDATE_AGENDA_SUCCESS',
    UPDATE_AGENDA_FAIL: 'UPDATE_AGENDA_FAIL',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    createRoleSuccess: false,
    createGoalSuccess: false,
    createAgendaSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_ROLE:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                createRoleSuccess: true,
            };
        case types.CREATE_ROLE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CREATE_GOAL:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_GOAL_SUCCESS:
            return {
                ...state,
                loading: false,
                createGoalSuccess: true,
            };
        case types.CREATE_GOAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.COMPLETE_GOAL:
            return {
                ...state,
                loading: true,
            };
        case types.COMPLETE_GOAL_SUCCESS:
            return {
                ...state,
                loading: false,
                completeGoalSuccess: true,
            };
        case types.COMPLETE_GOAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.MISS_GOAL:
            return {
                ...state,
                loading: true,
            };
        case types.MISS_GOAL_SUCCESS:
            return {
                ...state,
                loading: false,
                completeGoalSuccess: true,
            };
        case types.MISS_GOAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CREATE_AGENDA:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_AGENDA_SUCCESS:
            return {
                ...state,
                loading: false,
                createAgendaSuccess: true,
            };
        case types.CREATE_AGENDA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_AGENDA:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_AGENDA_SUCCESS:
            return {
                ...state,
                loading: false,
                updateAgendaSuccess: true,
            };
        case types.UPDATE_AGENDA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const createRole = (role) => {
    return (dispatch) => {
        try {
            const ref = db.collection('roles').doc();
            const id = ref.id;
            let roleObject = Object.assign({}, role);
            roleObject.uid = Firebase.firebase.auth().currentUser.uid;
            roleObject.id = id;
            roleObject.date = new Date();

            dispatch({ type: types.CREATE_ROLE, payload: roleObject });

            db.collection('roles')
                .doc(`${id}`)
                .set(roleObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_ROLE_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_ROLE_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_ROLE_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const createGoal = (goal) => {
    return (dispatch) => {
        try {
            const ref = db.collection('goals').doc();
            const id = ref.id;
            let goalObject = Object.assign({}, goal);
            goalObject.date = new Date();
            goalObject.uid = Firebase.firebase.auth().currentUser.uid;
            goalObject.id = id;

            dispatch({ type: types.CREATE_GOAL, payload: goalObject });

            db.collection('goals')
                .doc(`${id}`)
                .set(goalObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_GOAL_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_GOAL_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_GOAL_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const completeGoal = (ref, goal) => {
    return (dispatch) => {
        try {
            let goalObject = Object.assign({}, goal);
            dispatch({ type: types.COMPLETE_GOAL, payload: goalObject });
            db.collection('goals')
                .doc(ref.doc)
                .update(goalObject)
                .then((ref) => {
                    dispatch({
                        type: types.COMPLETE_GOAL_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.COMPLETE_GOAL_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.COMPLETE_GOAL_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const missGoal = (ref, goal) => {
    return (dispatch) => {
        try {
            let goalObject = Object.assign({}, goal);
            dispatch({ type: types.MISS_GOAL, payload: goalObject });
            db.collection('goals')
                .doc(ref.doc)
                .update(goalObject)
                .then((ref) => {
                    dispatch({
                        type: types.MISS_GOAL_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.MISS_GOAL_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.MISS_GOAL_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const createAgenda = (agenda) => {
    return (dispatch) => {
        try {
            let agendaObject = Object.assign({}, agenda);
            agendaObject.date = new Date();
            const ref = db.collection('agendas').doc();
            const id = ref.id;

            agendaObject.id = id;
            dispatch({ type: types.CREATE_AGENDA, payload: agendaObject });

            db.collection('agendas')
                .doc(`${id}`)
                .set(agendaObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_AGENDA_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_AGENDA_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_AGENDA_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const updateAgenda = (ref, agenda) => {
    return (dispatch) => {
        try {
            let agendaObject = Object.assign({}, agenda);
            dispatch({ type: types.UPDATE_AGENDA, payload: agendaObject });
            db.collection('agendas')
                .doc(ref.doc)
                .update(agendaObject)
                .then((ref) => {
                    dispatch({
                        type: types.UPDATE_AGENDA_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.UPDATE_AGENDA_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.UPDATE_AGENDA_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};
