import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, Text, TextInput, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import { updateUser } from '../ducks/UserSessionDuck';
import { Colors, Typography } from '../constants';

class BodyScreen extends Component {
    state = {
        isEditing: false,
        benchLevel: null,
        deadliftLevel: null,
        squatLevel: null,
        benchLevelThreeRep: null,
        deadliftLevelThreeRep: null,
        squatLevelThreeRep: null,
        twok: null,
        fiveK: null,
        tenK: null,
    };

    componentDidMount = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => this.setState({ isEditing: !this.state.isEditing })}>
                    <Text style={{ ...Typography.button }}>{this.state.isEditing ? 'Cancel' : 'Edit'}</Text>
                </TouchableOpacity>
            ),
        });
    };

    componentDidUpdate = () => {
        const { navigation } = this.props;
        navigation.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => this.setState({ isEditing: !this.state.isEditing })}>
                    <Text style={{ ...Typography.button }}>{this.state.isEditing ? 'Cancel' : 'Edit'}</Text>
                </TouchableOpacity>
            ),
        });
    };

    sumWeightLevels = () => {
        const { profile } = this.props;
        let sum = 0;
        if (profile.benchLevel) {
            sum += profile.benchLevel;
        }
        if (profile.deadliftLevel) {
            sum += profile.deadliftLevel;
        }
        if (profile.squatLevel) {
            sum += profile.squatLevel;
        }
        return sum;
    };

    sumThreeRepWeightLevels = () => {
        const { profile } = this.props;
        let sum = 0;
        if (profile.benchLevelThreeRep) {
            sum += profile.benchLevelThreeRep;
        }
        if (profile.deadliftLevelThreeRep) {
            sum += profile.deadliftLevelThreeRep;
        }
        if (profile.squatLevelThreeRep) {
            sum += profile.squatLevelThreeRep;
        }
        return sum;
    };

    render() {
        const { profile } = this.props;
        const { isEditing } = this.state;
        const { benchLevel, deadliftLevel, squatLevel, benchLevelThreeRep, deadliftLevelThreeRep, squatLevelThreeRep, twoK, fiveK, tenK } = profile;
        return (
            <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
                <View style={{ ...styles.header }}>
                    <Text style={{ ...Typography.h3 }}>Path to Super Saiyan</Text>
                </View>
                {isEditing ? (
                    <>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`bench 1 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.benchLevel ? this.state.benchLevel : `${benchLevel || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ benchLevel: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`deadlift 1 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.deadliftLevel ? this.state.deadliftLevel : `${deadliftLevel || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ deadliftLevel: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`squat 1 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.squatLevel ? this.state.squatLevel : `${squatLevel || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ squatLevel: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`bench 3 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.benchLevelThreeRep ? this.state.benchLevelThreeRep : `${benchLevelThreeRep || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ benchLevelThreeRep: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`deadlift 3 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.deadliftLevelThreeRep ? this.state.deadliftLevelThreeRep : `${deadliftLevelThreeRep || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ deadliftLevelThreeRep: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`squat 3 rep max:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.squatLevelThreeRep ? this.state.squatLevelThreeRep : `${squatLevelThreeRep || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ squatLevelThreeRep: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`2k:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.twoK ? this.state.twoK : `${twoK || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ twoK: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`5k:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.fiveK ? this.state.fiveK : `${fiveK || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ fiveK: text });
                                }}
                            />
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1, flex: 0 }}>{`10k:`}</Text>
                            <TextInput
                                placeholderTextColor={Colors.placeholderText}
                                style={{ ...Typography.body1, flex: 1, justifyContent: 'flex-end' }}
                                textAlign={'right'}
                                placeholder={'Enter Here'}
                                value={this.state.tenK ? this.state.tenK : `${tenK || ''}`}
                                onChangeText={(text) => {
                                    this.setState({ tenK: text });
                                }}
                            />
                        </View>
                        <TouchableOpacity
                            style={{ ...styles.header }}
                            onPress={() => {
                                const values = {
                                    benchLevel: this.state.benchLevel
                                        ? parseFloat(this.state.benchLevel)
                                        : this.props.benchLevel
                                        ? this.props.benchLevel
                                        : null,
                                    deadliftLevel: this.state.deadliftLevel
                                        ? parseFloat(this.state.deadliftLevel)
                                        : this.props.deadliftLevel
                                        ? this.props.deadliftLevel
                                        : null,
                                    squatLevel: this.state.squatLevel
                                        ? parseFloat(this.state.squatLevel)
                                        : this.props.squatLevel
                                        ? this.props.squatLevel
                                        : null,
                                    benchLevelThreeRep: this.state.benchLevelThreeRep
                                        ? parseFloat(this.state.benchLevelThreeRep)
                                        : this.props.benchLevelThreeRep
                                        ? this.props.benchLevelThreeRep
                                        : null,
                                    deadliftLevelThreeRep: this.state.deadliftLevelThreeRep
                                        ? parseFloat(this.state.deadliftLevelThreeRep)
                                        : this.props.deadliftLevelThreeRep
                                        ? this.props.deadliftLevelThreeRep
                                        : null,
                                    squatLevelThreeRep: this.state.squatLevelThreeRep
                                        ? parseFloat(this.state.squatLevelThreeRep)
                                        : this.props.squatLevelThreeRep
                                        ? this.props.squatLevelThreeRep
                                        : null,
                                    twok: this.state.twok ? this.state.twok : this.props.twok ? this.props.twok : null,
                                    fiveK: this.state.fiveK ? this.state.fiveK : this.props.fiveK ? this.props.fiveK : null,
                                    tenK: this.state.tenK ? this.state.tenK : this.props.tenK ? this.props.tenK : null,
                                };
                                this.props.updateUser({
                                    user: values,
                                });
                                this.setState({ isEditing: false });
                            }}
                        >
                            <Text style={{ ...Typography.button }}>Save</Text>
                        </TouchableOpacity>
                    </>
                ) : (
                    <>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1 }}>{`1000lb club:`}</Text>
                            <Text style={{ ...Typography.body1 }}>{`${this.sumWeightLevels()}/1000 lbs`}</Text>
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1 }}>{`3 rep total:`}</Text>
                            <Text style={{ ...Typography.body1 }}>{`${this.sumThreeRepWeightLevels()} lbs`}</Text>
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1 }}>{`2k:`}</Text>
                            <Text style={{ ...Typography.body1 }}>{`${profile.twoK || '∞'}`}</Text>
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1 }}>{`5k:`}</Text>
                            <Text style={{ ...Typography.body1 }}>{`${profile.fiveK || '∞'}`}</Text>
                        </View>
                        <View style={{ ...styles.row }}>
                            <Text style={{ ...Typography.subtitle1 }}>{`10k:`}</Text>
                            <Text style={{ ...Typography.body1 }}>{`${profile.tenK || '∞'}`}</Text>
                        </View>
                    </>
                )}
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 16,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginVertical: 8,
    },
});

const mapStateToProps = ({ firebase }) => {
    const { profile } = firebase;

    return {
        profile,
    };
};

export default connect(mapStateToProps, { updateUser })(BodyScreen);
