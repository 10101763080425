import Firebase, { db } from '../config/Firebase.js';
import * as NavigationHelper from '../navigation/NavigationHelper';
// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_HABIT: 'CREATE_HABIT',
    CREATE_HABIT_SUCCESS: 'CREATE_HABIT_SUCCESS',
    CREATE_HABIT_FAIL: 'CREATE_HABIT_FAIL',
    UPDATE_HABIT: 'UPDATE_HABIT',
    UPDATE_HABIT_SUCCESS: 'UPDATE_HABIT_SUCCESS',
    UPDATE_HABIT_FAIL: 'UPDATE_HABIT_FAIL',
    CREATE_HABIT_ACTIVITY: 'CREATE_HABIT_ACTIVITY',
    CREATE_HABIT_ACTIVITY_SUCCESS: 'CREATE_HABIT_ACTIVITY_SUCCESS',
    CREATE_HABIT_ACTIVITY_FAIL: 'CREATE_HABIT_ACTIVITY_FAIL',
    INCREMENT_HABIT_ACTIVITY: 'INCREMENT_HABIT_ACTIVITY',
    INCREMENT_HABIT_ACTIVITY_SUCCESS: 'INCREMENT_HABIT_ACTIVITY_SUCCESS',
    INCREMENT_HABIT_ACTIVITY_FAIL: 'INCREMENT_HABIT_ACTIVITY_FAIL',
    SAVE_CLICKED_HABIT: 'SAVE_CLICKED_HABIT',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    createHabitSuccess: false,
    createHabitActivitySuccess: false,
    incrementHabitActivitySuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_HABIT:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_HABIT_SUCCESS:
            return {
                ...state,
                loading: false,
                createHabitSuccess: true,
            };
        case types.CREATE_HABIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_HABIT:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_HABIT_SUCCESS:
            return {
                ...state,
                loading: false,
                updateHabitSuccess: true,
            };
        case types.UPDATE_HABIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CREATE_HABIT_ACTIVITY:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_HABIT_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                createHabitActivitySuccess: true,
            };
        case types.CREATE_HABIT_ACTIVITY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.INCREMENT_HABIT_ACTIVITY:
            return {
                ...state,
                loading: true,
            };
        case types.INCREMENT_HABIT_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                incrementHabitActivitySuccess: true,
            };
        case types.INCREMENT_HABIT_ACTIVITY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SAVE_CLICKED_HABIT:
            return {
                ...state,
                clickedHabitId: action.payload,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const createHabit = (habit) => {
    return (dispatch) => {
        try {
            const ref = db.collection('habits').doc();
            const id = ref.id;
            let habitObject = Object.assign({}, habit);
            habitObject.id = id;
            habitObject.uid = Firebase.firebase.auth().currentUser.uid;
            habitObject.date = new Date();

            dispatch({ type: types.CREATE_HABIT, payload: habitObject });

            db.collection('habits')
                .doc(`${id}`)
                .set(habitObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_HABIT_SUCCESS,
                        payload: ref,
                    });

                    NavigationHelper.navigate('SettingsScreen');
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_HABIT_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_HABIT_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const updateHabit = (ref, habit) => {
    return (dispatch) => {
        try {
            let habitObject = Object.assign({}, habit);
            dispatch({ type: types.UPDATE_HABIT, payload: habitObject });
            db.collection('habits')
                .doc(ref.doc)
                .update(habitObject)
                .then((ref) => {
                    dispatch({
                        type: types.UPDATE_HABIT_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.UPDATE_HABIT_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.UPDATE_HABIT_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const createHabitActivity = ({ habit, date }) => {
    return (dispatch) => {
        try {
            let habitActivityObject = {
                date: date,
                count: 1,
                level: habit.isBinary ? 4 : 1,
                habitId: habit.id,
                uid: habit.uid,
            };
            const ref = db.collection('habitActivity').doc();
            const id = ref.id;
            habitActivityObject.id = id;
            dispatch({ type: types.CREATE_HABIT_ACTIVITY, payload: habitActivityObject });

            db.collection('habitActivity')
                .doc(`${id}`)
                .set(habitActivityObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_HABIT_ACTIVITY_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_HABIT_ACTIVITY_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_HABIT_ACTIVITY_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const incrementHabitActivity = (ref, count, level) => {
    return (dispatch) => {
        try {
            dispatch({ type: types.INCREMENT_HABIT_ACTIVITY, payload: ref });
            db.collection('habitActivity')
                .doc(ref.doc)
                .update({
                    count: count + 1,
                    level: level === 4 ? 4 : level + 1,
                })
                .then((ref) => {
                    dispatch({
                        type: types.INCREMENT_HABIT_ACTIVITY_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.INCREMENT_HABIT_ACTIVITY_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.INCREMENT_HABIT_ACTIVITY_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const saveClickedHabit = (habitId) => {
    return (dispatch) => {
        dispatch({ type: types.SAVE_CLICKED_HABIT, payload: habitId });
    };
};
