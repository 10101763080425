import React from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, Button, Text, Switch } from 'react-native';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import DateTimePicker from '@react-native-community/datetimepicker';

import { Colors, Typography } from '../constants';

import { connect } from 'react-redux';
import { createHabit } from '../ducks/HabitsDuck';

const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
class CreateHabitScreen extends React.Component {
    state = {
        behavior: '',
        location: '',
        selectedIndex: 0,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        showTimePicker: false,
        time: new Date(),
        isBinaryIndex: 0,
    };

    _handleSubmit = () => {
        this.props.createHabit({
            title: this.state.title,
            isBinary: this.state.isBinaryIndex === 0,
            // message: this.state.message,
            // behavior: this.state.behavior
        });
    };

    render() {
        return (
            <View style={styles.container}>
                <TextInput style={styles.inputBox} value={this.state.title} onChangeText={(title) => this.setState({ title })} placeholder='Behavior' />
                <SegmentedControl
                    style={{ width: '85%', marginTop: 20, alignSelf: 'center' }}
                    values={['Binary', 'Levels']}
                    selectedIndex={this.state.isBinaryIndex}
                    onChange={(event) => {
                        this.setState({ isBinaryIndex: event.nativeEvent.selectedSegmentIndex });
                    }}
                />
                {/* <TextInput style={styles.inputBox} value={this.state.location} onChangeText={(message) => this.setState({ message })} placeholder='Location' /> */}
                {/* <SegmentedControl
                    style={{ width: '85%', marginTop: 20, alignSelf: 'center' }}
                    values={['Daily', 'Custom']}
                    selectedIndex={this.state.selectedIndex}
                    onChange={(event) => {
                        this.setState({ selectedIndex: event.nativeEvent.selectedSegmentIndex });
                    }}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 40, marginTop: 40 }}>
                    <Text style={{ ...Typography.subtitle1, flex: 1 }}>Time of Day</Text>
                    <DateTimePicker
                        testID='dateTimePicker'
                        value={this.state.time}
                        mode={'time'}
                        is24Hour={true}
                        display='default'
                        onChange={(event, selectedDate) => {
                            const currentDate = selectedDate || this.state.date;
                            this.setState({ time: currentDate });
                        }}
                        style={{ minWidth: 90 }}
                    />
                    <Button onPress={() => this.setState({ showTimePicker: !this.state.showTimePicker })} title='Edit' />
                </View> */}

                {/* {this.state.selectedIndex === 1 && (
                    <>
                        {daysOfTheWeek.map((dayOfTheWeek) => (
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 40, marginTop: 20 }}>
                                <Text style={{ ...Typography.subtitle1 }}>{dayOfTheWeek}</Text>
                                <Switch
                                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                                    thumbColor={!!this.state[dayOfTheWeek] ? '#f5dd4b' : '#f4f3f4'}
                                    ios_backgroundColor='#3e3e3e'
                                    onValueChange={() => this.setState({ [dayOfTheWeek]: !this.state[dayOfTheWeek] })}
                                    value={this.state[dayOfTheWeek]}
                                />
                            </View>
                        ))}
                    </>
                )} */}
                <TouchableOpacity style={styles.button} onPress={this._handleSubmit}>
                    <Text style={styles.buttonText}>Create Habit</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'stretch',
        paddingTop: 30,
    },
    inputBox: {
        ...Typography.subtitle1,
        width: '85%',
        margin: 10,
        padding: 15,
        borderColor: Colors.blueSapphire,
        borderBottomWidth: 1,
        textAlign: 'center',
        alignSelf: 'center',
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
});

const mapStateToProps = ({ habits }) => {
    const { createHabitSuccess, error, loading } = habits;

    return {
        createHabitSuccess,
        error,
        loading,
    };
};

export default connect(mapStateToProps, {
    createHabit,
})(CreateHabitScreen);
