import React, { Component } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View, FlatList, Platform, TextInput } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Ionicons } from '@expo/vector-icons';
import moment from 'moment';
// import KeyboardSpacer from 'react-native-keyboard-spacer';

import { CommentItem } from '../components';
import { updateUser } from '../ducks/UserSessionDuck';
import { updateHabit } from '../ducks/HabitsDuck';
import { Colors, Typography } from '../constants';
import { db } from '../config/Firebase';

class DetailedHabitScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _renderVerticalSeparator = () => {
        return <View style={{ height: 8 }} />;
    };

    render() {
        const { habit } = this.props;

        if (habit) {
            let date = moment.unix(habit.date.seconds).format('MMM. D');

            let isLoved = false;

            if (habit.loves) {
                const index = habit.loves.findIndex((love) => {
                    return this.props.profile.uid === love.id;
                });

                if (index != -1) {
                    isLoved = true;
                }
            }

            return (
                <View
                    style={{
                        flex: 1,
                        backgroundColor: Colors.richBlackFogra,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'stretch',
                            marginVertical: 24,
                            marginHorizontal: 24,
                            borderColor: Colors.white,
                            borderWidth: 2,
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                            }}
                            onPress={() => {
                                this.setState({
                                    expanded: !this.state.expanded,
                                });
                            }}
                            activeOpacity={0.5}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: Colors.blueSapphire,
                                }}
                            >
                                <Text
                                    style={{
                                        ...Typography.subtitle1,
                                        color: Colors.white,
                                    }}
                                >
                                    {date}
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <View
                            style={{
                                height: 200,
                                backgroundColor: Colors.viridianGreen,
                                justifyContent: 'space-between',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    margin: 12,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <ScrollView>
                                    <Text
                                        style={{
                                            ...Typography.body1,
                                            flex: 1,
                                            color: Colors.white,
                                            marginRight: 8,
                                        }}
                                    >
                                        {habit.message}
                                    </Text>
                                </ScrollView>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (isLoved) {
                                            let updatedLoves = [...habit.loves];

                                            updatedLoves = updatedLoves.filter((item) => item.id !== this.props.profile.uid);
                                            this.props.updateHabit(
                                                {
                                                    collection: 'habits',
                                                    doc: habit.id,
                                                },
                                                { loves: updatedLoves }
                                            );
                                            this.props.updateUser({
                                                user: {
                                                    loves: updatedLoves,
                                                },
                                            });
                                        } else {
                                            let updatedLoves = [];
                                            if (habit.loves) {
                                                updatedLoves = [...habit.loves];
                                            }
                                            updatedLoves.push(db.collection('users').doc(this.props.profile.uid));
                                            this.props.updateHabit(
                                                {
                                                    collection: 'habits',
                                                    doc: habit.id,
                                                },
                                                { loves: updatedLoves }
                                            );
                                            this.props.updateUser({
                                                user: {
                                                    loves: updatedLoves,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    <Ionicons
                                        size={26}
                                        color={isLoved ? Colors.gamboge : Colors.white}
                                        name={Platform.OS === 'ios' ? `ios-heart${isLoved ? '' : '-empty'}` : `md-heart${isLoved ? '' : '-empty'}`}
                                    />
                                </TouchableOpacity>
                            </View>
                            <View
                                style={{
                                    height: 30,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingHorizontal: 12,
                                    backgroundColor: Colors.rufous,
                                }}
                            >
                                <Text
                                    style={{
                                        ...Typography.body1,
                                        color: Colors.white,
                                    }}
                                >
                                    {habit.loves && habit.loves.length > 0 ? `${habit.loves.length} loves` : `no loves yet`}
                                </Text>
                                {habit.comments && habit.comments.length > 0 && (
                                    <Text
                                        style={{
                                            ...Typography.body1,
                                            color: Colors.white,
                                        }}
                                    >
                                        {`${habit.comments.length}`} comments
                                    </Text>
                                )}
                            </View>
                        </View>
                    </View>
                    {habit && habit.comments && habit.comments.length > 0 ? (
                        <FlatList
                            style={styles.flatListStyle}
                            data={habit.comments ? habit.comments : []}
                            renderItem={({ item }) => <CommentItem userName={item.userName} comment={item.comment} createdAt={item.createdAt} />}
                            ListHeaderComponent={this._renderVerticalSeparator}
                            ListFooterComponent={this._renderVerticalSeparator}
                            ItemSeparatorComponent={this._renderVerticalSeparator}
                            keyExtractor={(item) => item}
                            onEndReachedThreshold={50}
                        />
                    ) : (
                        <View style={{ flex: 1 }} />
                    )}
                    <View>
                        <TextInput
                            placeholder={'Type a comment'}
                            placeholderTextColor={Colors.offWhite}
                            style={{
                                ...Typography.body1,
                                height: 40,
                                borderColor: Colors.white,
                                borderTopWidth: 2,
                                color: Colors.white,
                                paddingLeft: 36,
                                paddingRight: 12,
                                backgroundColor: Colors.blueSapphire,
                            }}
                            onChangeText={(text) => {
                                this.setState({ message: text });
                            }}
                            textAlignVertical='center'
                            value={this.state.message}
                            returnKeyLabel={'done'}
                            returnKeyType={'done'}
                            autoFocus
                            onSubmitEditing={() => {
                                const { habit } = this.props;
                                const messageToAdd = `${this.state.message ? this.state.message : ''}`;
                                if (messageToAdd !== undefined && messageToAdd !== '') {
                                    let updatedComments = [];
                                    if (habit.loves) {
                                        updatedComments = [...habit.comments];
                                    }
                                    updatedComments.push({
                                        userName: this.props.profile.userName,
                                        comment: messageToAdd,
                                        createdAt: Date.now(),
                                    });
                                    this.props.updateHabit(
                                        {
                                            collection: 'habits',
                                            doc: habit.id,
                                        },
                                        { comments: updatedComments }
                                    );
                                    this.setState({ message: '' });
                                }
                            }}
                        />
                        {/* <KeyboardSpacer /> */}
                    </View>
                </View>
            );
        } else {
            return <View style={styles.container}></View>;
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    contentContainer: {
        paddingTop: 30,
    },
    flatListStyle: {
        flex: 1,
    },
});

const mapStateToProps = ({ firestore, firebase, habits }) => {
    const { data } = firestore;
    const { clickedHabitId } = habits;
    const { profile } = firebase;
    return {
        habit: data && data.clickedHabit ? data.clickedHabit : null,
        habitId: clickedHabitId,
        profile,
    };
};

export default compose(
    connect(mapStateToProps, {
        updateUser,
        updateHabit,
    }),
    firestoreConnect((props) => [{ collection: 'habits', doc: props.habitId, storeAs: 'clickedHabit' }])
)(DetailedHabitScreen);
