import Firebase, { db } from '../config/Firebase.js';
import * as NavigationHelper from '../navigation/NavigationHelper';
// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_TASK: 'CREATE_TASK',
    CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
    CREATE_TASK_FAIL: 'CREATE_TASK_FAIL',
    UPDATE_TASK: 'UPDATE_TASK',
    UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
    UPDATE_TASK_FAIL: 'UPDATE_TASK_FAIL',
    COMPLETE_TASK: 'COMPLETE_TASK',
    COMPLETE_TASK_SUCCESS: 'COMPLETE_TASK_SUCCESS',
    COMPLETE_TASK_FAIL: 'COMPLETE_TASK_FAIL',
    MISS_TASK: 'MISS_TASK',
    MISS_TASK_SUCCESS: 'MISS_TASK_SUCCESS',
    MISS_TASK_FAIL: 'MISS_TASK_FAIL',
    SAVE_CLICKED_TASK: 'SAVE_CLICKED_TASK',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    createTaskSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_TASK:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                createTaskSuccess: true,
            };
        case types.CREATE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_TASK:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                updateTaskSuccess: true,
            };
        case types.UPDATE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.COMPLETE_TASK:
            return {
                ...state,
                loading: true,
            };
        case types.COMPLETE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                completeTaskSuccess: true,
            };
        case types.COMPLETE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.MISS_TASK:
            return {
                ...state,
                loading: true,
            };
        case types.MISS_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                completeTaskSuccess: true,
            };
        case types.MISS_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SAVE_CLICKED_TASK:
            return {
                ...state,
                clickedTaskId: action.payload,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const createTask = (task) => {
    return (dispatch) => {
        try {
            const ref = db.collection('tasks').doc();
            const id = ref.id;
            let taskObject = Object.assign({}, task);
            taskObject.id = id;
            taskObject.uid = Firebase.firebase.auth().currentUser.uid;
            taskObject.date = new Date();

            dispatch({ type: types.CREATE_TASK, payload: taskObject });

            db.collection('tasks')
                .doc(`${id}`)
                .set(taskObject)
                .then((ref) => {
                    dispatch({
                        type: types.CREATE_TASK_SUCCESS,
                        payload: ref,
                    });

                    NavigationHelper.navigate('SettingsScreen');
                })
                .catch((err) => {
                    dispatch({
                        type: types.CREATE_TASK_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.CREATE_TASK_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const updateTask = (ref, task) => {
    return (dispatch) => {
        try {
            let taskObject = Object.assign({}, task);
            dispatch({ type: types.UPDATE_TASK, payload: taskObject });
            db.collection('tasks')
                .doc(ref.doc)
                .update(taskObject)
                .then((ref) => {
                    dispatch({
                        type: types.UPDATE_TASK_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.UPDATE_TASK_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.UPDATE_TASK_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const completeTask = (ref, task) => {
    return (dispatch) => {
        try {
            let taskObject = Object.assign({}, task);
            dispatch({ type: types.COMPLETE_TASK, payload: taskObject });
            db.collection('tasks')
                .doc(ref.doc)
                .update(taskObject)
                .then((ref) => {
                    dispatch({
                        type: types.COMPLETE_TASK_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.COMPLETE_TASK_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.COMPLETE_TASK_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const missTask = (ref, task) => {
    return (dispatch) => {
        try {
            let taskObject = Object.assign({}, task);
            dispatch({ type: types.MISS_TASK, payload: taskObject });
            db.collection('tasks')
                .doc(ref.doc)
                .update(taskObject)
                .then((ref) => {
                    dispatch({
                        type: types.MISS_TASK_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.MISS_TASK_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.MISS_TASK_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};

export const saveClickedTask = (taskId) => {
    return (dispatch) => {
        dispatch({ type: types.SAVE_CLICKED_TASK, payload: taskId });
    };
};
