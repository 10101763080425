import React from 'react';
import { Text, View, Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { TabBarIcon } from '../components';
import { Colors, Typography } from '../constants';
import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ChooseYourCharacterScreen from '../screens/ChooseYourCharacterScreen';
import HabitsScreen from '../screens/HabitsScreen';
import CreateHabitScreen from '../screens/CreateHabitScreen';
import AgendaScreen from '../screens/AgendaScreen';
import CreateAgendaScreen from '../screens/CreateAgendaScreen';
import CreateTaskScreen from '../screens/CreateTaskScreen';
import TasksScreen from '../screens/TasksScreen';
import BodyScreen from '../screens/BodyScreen';
import FrensScreen from '../screens/FrensScreen';
import { headerConfig } from '.';

const HomeStackNavigator = createNativeStackNavigator();

function HomeStack() {
    return (
        <HomeStackNavigator.Navigator initialRouteName={'HomeScreen'} screenOptions={headerConfig}>
            <HomeStackNavigator.Screen name='HomeScreen' component={HomeScreen} options={{ title: 'Home' }} />
        </HomeStackNavigator.Navigator>
    );
}

const HabitsStackNavigator = createNativeStackNavigator();

function HabitsStack() {
    return (
        <HabitsStackNavigator.Navigator initialRouteName={'HabitsScreen'} screenOptions={headerConfig}>
            <HabitsStackNavigator.Screen name='HabitsScreen' component={HabitsScreen} options={{ title: 'Habits' }} />
            <HabitsStackNavigator.Screen name='CreateHabitScreen' component={CreateHabitScreen} options={{ title: 'Create Habit' }} />
        </HabitsStackNavigator.Navigator>
    );
}

const AgendaStackNavigator = createNativeStackNavigator();

function AgendaStack() {
    return (
        <AgendaStackNavigator.Navigator initialRouteName={'AgendaScreen'} screenOptions={headerConfig}>
            <AgendaStackNavigator.Screen name='AgendaScreen' component={AgendaScreen} options={{ title: 'Agenda' }} />
            <AgendaStackNavigator.Screen name='CreateAgendaScreen' component={CreateAgendaScreen} options={{ title: 'Create Agenda' }} />
        </AgendaStackNavigator.Navigator>
    );
}

const TasksStackNavigator = createNativeStackNavigator();

function TasksStack() {
    return (
        <TasksStackNavigator.Navigator initialRouteName={'TasksScreen'} screenOptions={headerConfig}>
            <TasksStackNavigator.Screen name='TasksScreen' component={TasksScreen} options={{ title: 'Tasks' }} />
            <TasksStackNavigator.Screen name='CreateTaskScreen' component={CreateTaskScreen} options={{ title: 'Create Task' }} />
        </TasksStackNavigator.Navigator>
    );
}

const FrensStackNavigator = createNativeStackNavigator();

function FrensStack() {
    return (
        <FrensStackNavigator.Navigator initialRouteName={'FrensScreen'} screenOptions={headerConfig}>
            <FrensStackNavigator.Screen name='FrensScreen' component={FrensScreen} options={{ title: 'Frens' }} />
        </FrensStackNavigator.Navigator>
    );
}

const BodyStackNavigator = createNativeStackNavigator();

function BodyStack() {
    return (
        <BodyStackNavigator.Navigator initialRouteName={'BodyScreen'} screenOptions={headerConfig}>
            <BodyStackNavigator.Screen name='BodyScreen' component={BodyScreen} options={{ title: 'Body' }} />
        </BodyStackNavigator.Navigator>
    );
}

const SettingsStackNavigator = createNativeStackNavigator();

function SettingsStack() {
    return (
        <SettingsStackNavigator.Navigator initialRouteName={'SettingsScreen'} screenOptions={headerConfig}>
            <SettingsStackNavigator.Screen name='SettingsScreen' component={SettingsScreen} options={{ title: 'Settings' }} />
            <SettingsStackNavigator.Screen
                name='ChooseYourCharacterScreen'
                component={ChooseYourCharacterScreen}
                options={{ title: 'Choose Your Character' }}
            />
        </SettingsStackNavigator.Navigator>
    );
}

const Tab = createBottomTabNavigator();

export const Tabs = () => {
    return (
        <Tab.Navigator
            screenOptions={({ route }) => ({
                headerShown: false,
                tabBarStyle: { backgroundColor: Colors.blueSapphire, borderTopWidth: 0, borderBottomWidth: 0, paddingBottom: 15 },
            })}
        >
            {/* <Tab.Screen
                name='HomeStack'
                component={HomeStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Home
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? (
                            <View />
                        ) : (
                            <TabBarIcon
                                focused={focused}
                                name={Platform.OS === 'ios' ? `ios-heart${focused ? '' : '-outline'}` : `md-heart${focused ? '' : '-outline'}`}
                            />
                        ),
                }}
            /> */}
            <Tab.Screen
                name='HabitsStack'
                component={HabitsStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Habits
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-barcode' : 'md-barcode'} />,
                }}
            />
            <Tab.Screen
                name='AgendaStack'
                component={AgendaStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Agenda
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-barcode' : 'md-barcode'} />,
                }}
            />
            <Tab.Screen
                name='TasksStack'
                component={TasksStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Tasks
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-stopwatch' : 'md-stopwatch'} />,
                }}
            />
            {/* <Tab.Screen
                name='BodyStack'
                component={BodyStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Body
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-body' : 'md-body'} />,
                }}
            /> */}
            <Tab.Screen
                name='FrensStack'
                component={FrensStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Frens
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-body' : 'md-body'} />,
                }}
            />
            <Tab.Screen
                name='SettingsStack'
                component={SettingsStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Settings
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-options' : 'md-options'} />,
                }}
            />
        </Tab.Navigator>
    );
};

export default Tabs;
