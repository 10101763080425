export default {
    Shia: {
        miss: 'shia-miss.mp3',
        one: 'shia-1.mp3',
        two: 'shia-2.mp3',
        three: 'shia-3.mp3',
        four: 'shia-4.mp3',
        five: 'shia-5.mp3',
        six: 'shia-6.mp3',
        max: 'shia-max.mp3',
        intro: 'shia-intro-1.mp3',
        intro2: 'shia-intro-2.mp3',
    },
    Giovanni: {
        miss: 'giovanni-miss.wav',
        one: 'giovanni-1.wav',
        two: 'giovanni-2.wav',
        three: 'giovanni-3.wav',
        four: 'giovanni-4.wav',
        five: 'giovanni-5.wav',
        six: 'giovanni-6.wav',
        max: 'giovanni-max.wav',
        intro: 'giovanni-intro-1.wav',
        intro2: 'giovanni-intro-2.wav',
    },
    Sabrina: {
        miss: 'sabrina-miss.wav',
        one: 'sabrina-1.wav',
        two: 'sabrina-2.wav',
        three: 'sabrina-3.wav',
        four: 'sabrina-4.wav',
        five: 'sabrina-5.wav',
        six: 'sabrina-6.wav',
        max: 'sabrina-max.wav',
        intro: 'sabrina-intro-1.wav',
        intro2: 'sabrina-intro-2.wav',
    },
    'Lt. Surge': {
        miss: 'lt-surge-miss.wav',
        one: 'lt-surge-1.wav',
        two: 'lt-surge-2.wav',
        three: 'lt-surge-3.wav',
        four: 'lt-surge-4.wav',
        five: 'lt-surge-5.wav',
        six: 'lt-surge-6.wav',
        max: 'lt-surge-max.wav',
        intro: 'lt-surge-intro-1.wav',
        intro2: 'lt-surge-intro-2.wav',
    },
    Tracey: {
        miss: 'tracey-miss.wav',
        one: 'tracey-1.wav',
        two: 'tracey-2.wav',
        three: 'tracey-3.wav',
        four: 'tracey-4.wav',
        five: 'tracey-5.wav',
        six: 'tracey-6.wav',
        max: 'tracey-max.wav',
        intro: 'tracey-intro-1.wav',
        intro2: 'tracey-intro-2.wav',
    },
    Lorelai: {
        miss: 'lorelai-miss.wav',
        one: 'lorelai-1.wav',
        two: 'lorelai-2.wav',
        three: 'lorelai-3.wav',
        four: 'lorelai-4.wav',
        five: 'lorelai-5.wav',
        six: 'lorelai-6.wav',
        max: 'lorelai-max.wav',
        intro: 'lorelai-intro-1.wav',
        intro2: 'lorelai-intro-2.wav',
    },
    Blaine: {
        miss: 'blaine-miss.wav',
        one: 'blaine-1.wav',
        two: 'blaine-2.wav',
        three: 'blaine-3.wav',
        four: 'blaine-4.wav',
        five: 'blaine-5.wav',
        six: 'blaine-6.wav',
        max: 'blaine-max.wav',
        intro: 'blaine-intro-1.wav',
        intro2: 'blaine-intro-2.wav',
    },
    'Team Rocket': {
        miss: 'team-rocket-miss.wav',
        one: 'team-rocket-1.wav',
        two: 'team-rocket-2.wav',
        three: 'team-rocket-3.wav',
        four: 'team-rocket-4.wav',
        five: 'team-rocket-5.wav',
        six: 'team-rocket-6.wav',
        max: 'team-rocket-max.wav',
        intro: 'team-rocket-intro-1.wav',
        intro2: 'team-rocket-intro-2.wav',
    },
    characters: ['Shia', 'Giovanni', 'Sabrina', 'Lt. Surge', 'Tracey', 'Lorelai', 'Blaine', 'Team Rocket'],
};
