import React from 'react';
import ActivityCalendar from 'react-activity-calendar';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Button, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Colors, Typography } from '../../constants';
import { incrementHabitActivity, createHabitActivity } from '../../ducks/HabitsDuck';
import { connect } from 'react-redux';

// {
//     count: 1,
//     date: '2022-01-01',
//     level: 1
//   }
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'center',
        paddingTop: 30,
    },
    inputBox: {
        ...Typography.subtitle1,
        width: '85%',
        margin: 10,
        padding: 15,
        borderColor: Colors.blueSapphire,
        borderBottomWidth: 1,
        textAlign: 'center',
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 100,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
});

const HabitCalendar = ({
    habitActivityData = [],
    habit = {
        title: '',
    },
    incrementHabitActivity,
    createHabitActivity,
}) => {
    const today = new Date();
    const thisYear = today.getFullYear();
    const todayDate = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    return (
        <View
            style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                marginVertical: 8,
                marginHorizontal: 24,
                paddingHorizontal: 24,
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        ...Typography.subtitle1,
                        fontSize: 24,
                        marginBottom: 8,
                    }}
                >
                    {habit.title}
                </Text>
                <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                        const habitActivityArr = habitActivityData.filter((habitActivity) => habitActivity.date === todayDate);
                        if (habitActivityArr[0]) {
                            if (habit.isBinary) return;
                            incrementHabitActivity(
                                {
                                    collection: 'habit',
                                    doc: habitActivityArr[0].id,
                                },
                                habitActivityArr[0].count,
                                habitActivityArr[0].level
                            );
                        } else {
                            createHabitActivity({ habit, date: todayDate });
                        }
                    }}
                >
                    <Text style={styles.buttonText}>I DID IT</Text>
                </TouchableOpacity>
            </View>
            <ActivityCalendar
                color={Colors.turkishSea}
                data={[
                    {
                        count: 0,
                        date: `${thisYear}-01-01`,
                        level: 0,
                    },
                    ...habitActivityData,
                    { count: 0, date: tomorrow, level: 0 },
                ]}
                labels={{
                    legend: {
                        less: 'Less',
                        more: 'More',
                    },
                    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    tooltip: '<strong>{{count}} contributions</strong> on {{date}}',
                    totalCount: '{{count}} contributions in {{year}}',
                    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                }}
                showWeekdayLabels
            >
                <ReactTooltip html />
            </ActivityCalendar>
        </View>
    );
};

const mapStateToProps = ({ firebase }) => {
    const { auth, profile } = firebase;

    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps, {
    incrementHabitActivity,
    createHabitActivity,
})(HabitCalendar);
