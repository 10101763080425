import React, { Component } from 'react';
import Constants from 'expo-constants';
import { ScrollView, StyleSheet, View, FlatList, Platform } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { Notifications } from 'expo';
// import * as Permissions from 'expo-permissions';
import * as Notifications from 'expo-notifications';

import { firestoreConnect } from 'react-redux-firebase';

import { updateUser } from '../ducks/UserSessionDuck';
import { updateHabit, saveClickedHabit } from '../ducks/HabitsDuck';
import { HabitItem } from '../components';
import Colors from '../constants/Colors';

class HomeScreen extends Component {
    state = {
        isFetching: false,
    };

    async componentDidMount() {
        // await this.registerForPushNotificationsAsync();
    }

    // async registerForPushNotificationsAsync() {
    //     let token;
    //     if (Constants.isDevice) {
    //         const { status: existingStatus } = await Notifications.getPermissionsAsync();
    //         let finalStatus = existingStatus;
    //         if (existingStatus !== 'granted') {
    //             const { status } = await Notifications.requestPermissionsAsync();
    //             finalStatus = status;
    //         }
    //         if (finalStatus !== 'granted') {
    //             alert('Failed to get push token for push notification!');
    //             return;
    //         }
    //         token = (await Notifications.getExpoPushTokenAsync()).data;
    //         console.log(token);
    //     } else {
    //         alert('Must use physical device for Push Notifications');
    //     }

    //     if (Platform.OS === 'android') {
    //         Notifications.setNotificationChannelAsync('default', {
    //             name: 'default',
    //             importance: Notifications.AndroidImportance.MAX,
    //             vibrationPattern: [0, 250, 250, 250],
    //             lightColor: '#FF231F7C',
    //         });
    //     }

    //     if (token) {
    //         let newUser = {};
    //         newUser.expo_token = token;
    //         this.props.updateUser({
    //             user: newUser,
    //         });
    //     }
    //     // return token;
    // }

    // _registerForPushNotificationsAsync = async () => {
    //     const { status: existingStatus } = await Permissions.getAsync(
    //         Permissions.NOTIFICATIONS
    //     );
    //     let finalStatus = existingStatus;
    //     // only ask if permissions have not already been determined, because
    //     // iOS won't necessarily prompt the user a second time.
    //     if (existingStatus !== 'granted') {
    //         // Android remote notification permissions are granted during the app
    //         // install, so this will only ask on iOS
    //         const { status } = await Permissions.askAsync(
    //             Permissions.NOTIFICATIONS
    //         );
    //         finalStatus = status;
    //     }
    //     // Get the token that uniquely identifies this device
    //     if (finalStatus !== 'undetermined') {
    //         let token = await Notifications.getExpoPushTokenAsync();
    //         if (token) {
    //             let newUser = {};
    //             newUser.expo_token = token;
    //             this.props.updateUser({
    //                 user: newUser,
    //             });
    //         }
    //     }
    // };

    _renderHeader = () => {
        return <View style={{ height: 8 }} />;
    };

    _renderListEmpty = () => {
        return <View>{/* <Text>empty tho</Text> */}</View>;
    };

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    data={this.props.habits}
                    renderItem={({ item }) => {
                        return (
                            <HabitItem
                                onPress={() => this._onHabitItemPress(item)}
                                habit={item}
                                updateHabit={(ref, habit) => {
                                    this.props.updateHabit(ref, habit);
                                }}
                                updateUser={(user) => {
                                    this.props.updateUser(user);
                                }}
                                onDetailPress={() => {
                                    this.props.saveClickedHabit(item.id);
                                    this.props.navigation.navigate('DetailedHabitScreen');
                                }}
                                isFirst={this.props.habits[0].id === item.id}
                            />
                        );
                    }}
                    backgroundColor={Colors.mediumChampagne}
                    keyExtractor={(item) => `${item.id}`}
                    refreshing={this.state.isFetching}
                    onRefresh={this._onRefresh}
                    ListHeaderComponent={this._renderHeader}
                    ListFooterComponent={this._renderFooter}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.1}
                    ListEmptyComponent={this._renderListEmpty}
                    onScroll={this.handleScroll}
                    ref={(ref) => {
                        this._flatList = ref;
                    }}
                />
                <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}></ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    contentContainer: {
        paddingTop: 30,
    },
});

const mapStateToProps = ({ firestore }) => {
    const { ordered } = firestore;

    return {
        habits: ordered.habits ? ordered.habits : [],
    };
};

export default compose(
    connect(mapStateToProps, {
        updateUser,
        updateHabit,
        saveClickedHabit,
    }),
    firestoreConnect((props) => [
        {
            collection: 'habits',
            orderBy: ['date', 'desc'],
        },
    ])
)(HomeScreen);
