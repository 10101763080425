// PROD
export default {
    ENVIRONMENT: 'Production',
    VERSION: 'v1.0.1',
    LOGGER: false,
};

// // DEV
// export default {
//     ENVIRONMENT: 'Dev',
//     VERSION: 'v0.0.1',
//     LOGGER: false,
// };

export const FeatureFlags = {
    HELLOWORLD: false,
};
