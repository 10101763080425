import Colors from './Colors';
import Typography from './Typography';

const GlobalStyles = {
    square5: {
        height: 5,
        width: 5,
    },
    square10: {
        height: 10,
        width: 10,
    },
    square20: {
        height: 20,
        width: 20,
    },
    square30: {
        height: 30,
        width: 30,
    },
    square40: {
        height: 40,
        width: 40,
    },
    square50: {
        height: 50,
        width: 50,
    },
    backgroundGray: {
        backgroundColor: Colors.lightGray,
    },
    flex: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    flex3: {
        flex: 3,
    },
    flex4: {
        flex: 4,
    },
    flex5: {
        flex: 5,
    },
    flex6: {
        flex: 6,
    },
    flex7: {
        flex: 7,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    rowCenterAlign: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowCenterAlignFlex: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    justifySpaceBetween: {
        justifyContent: 'space-between',
    },
    justifyFlexEnd: {
        justifyContent: 'flex-end',
    },
    justifyFlexStart: {
        justifyContent: 'flex-start',
    },
    justifySpaceEvenly: {
        justifyContent: 'space-evenly',
    },
    alignStretch: {
        alignItems: 'stretch',
    },
    alignFlexEnd: {
        alignItems: 'flex-end',
    },
    alignFlexStart: {
        alignItems: 'flex-start',
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    colCenterAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    colCenterAlignFlex: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selfCentered: {
        alignSelf: 'center',
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignSelfStretch: {
        alignSelf: 'stretch',
    },
    alignSelfFlexStart: {
        alignSelf: 'flex-start',
    },
    alignSelfFlexEnd: {
        alignSelf: 'flex-end',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    wrap: {
        flexWrap: 'wrap',
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    white: {
        backgroundColor: 'white',
    },
    colorWhite: {
        color: 'white',
    },
    fullWidth: {
        width: '100%',
    },
    padding0: {
        padding: 0,
    },
    padding5: {
        padding: 5,
    },
    padding10: {
        padding: 10,
    },
    padding20: {
        padding: 20,
    },
    padding30: {
        padding: 30,
    },
    padding40: {
        padding: 40,
    },
    padding50: {
        padding: 50,
    },
    margin10: {
        margin: 10,
    },
    margin20: {
        margin: 20,
    },
    margin30: {
        margin: 30,
    },
    margin40: {
        margin: 40,
    },
    margin50: {
        margin: 50,
    },
    marginTop5: {
        marginTop: 5,
    },
    marginTop10: {
        marginTop: 10,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginTop30: {
        marginTop: 30,
    },
    marginTop40: {
        marginTop: 40,
    },
    marginTop50: {
        marginTop: 50,
    },
    marginBottom5: {
        marginBottom: 5,
    },
    marginBottom10: {
        marginBottom: 10,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    marginBottom30: {
        marginBottom: 30,
    },
    marginBottom40: {
        marginBottom: 40,
    },
    marginBottom50: {
        marginBottom: 50,
    },
    marginRight5: {
        marginRight: 5,
    },
    marginRight10: {
        marginRight: 10,
    },
    marginRight20: {
        marginRight: 20,
    },
    marginRight30: {
        marginRight: 30,
    },
    marginRight40: {
        marginRight: 40,
    },
    marginRight50: {
        marginRight: 50,
    },
    marginLeft5: {
        marginLeft: 5,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    marginLeft20: {
        marginLeft: 20,
    },
    marginLeft30: {
        marginLeft: 30,
    },
    marginLeft40: {
        marginLeft: 40,
    },
    marginLeft50: {
        marginLeft: 50,
    },
    marginVertical5: {
        marginTop: 5,
        marginBottom: 5,
    },
    marginVertical10: {
        marginTop: 10,
        marginBottom: 10,
    },
    marginVertical20: {
        marginTop: 20,
        marginBottom: 20,
    },
    marginVertical30: {
        marginTop: 30,
        marginBottom: 30,
    },
    marginVertical40: {
        marginTop: 40,
        marginBottom: 40,
    },
    marginVertical50: {
        marginTop: 50,
        marginBottom: 50,
    },
    marginHorizontal5: {
        marginLeft: 5,
        marginRight: 5,
    },
    marginHorizontal10: {
        marginLeft: 10,
        marginRight: 10,
    },
    marginHorizontal20: {
        marginLeft: 20,
        marginRight: 20,
    },
    marginHorizontal30: {
        marginLeft: 30,
        marginRight: 30,
    },
    marginHorizontal40: {
        marginLeft: 40,
        marginRight: 40,
    },
    marginHorizontal50: {
        marginLeft: 50,
        marginRight: 50,
    },
    paddingTop5: {
        paddingTop: 5,
    },
    paddingTop10: {
        paddingTop: 10,
    },
    paddingTop20: {
        paddingTop: 20,
    },
    paddingTop30: {
        paddingTop: 30,
    },
    paddingTop40: {
        paddingTop: 40,
    },
    paddingTop50: {
        paddingTop: 50,
    },
    paddingBottom5: {
        paddingBottom: 5,
    },
    paddingBottom10: {
        paddingBottom: 10,
    },
    paddingBottom20: {
        paddingBottom: 20,
    },
    paddingBottom30: {
        paddingBottom: 30,
    },
    paddingBottom40: {
        paddingBottom: 40,
    },
    paddingBottom50: {
        paddingBottom: 50,
    },
    paddingRight5: {
        paddingRight: 5,
    },
    paddingRight10: {
        paddingRight: 10,
    },
    paddingRight20: {
        paddingRight: 20,
    },
    paddingRight30: {
        paddingRight: 30,
    },
    paddingRight40: {
        paddingRight: 40,
    },
    paddingRight50: {
        paddingRight: 50,
    },
    paddingLeft5: {
        paddingLeft: 5,
    },
    paddingLeft10: {
        paddingLeft: 10,
    },
    paddingLeft20: {
        paddingLeft: 20,
    },
    paddingLeft30: {
        paddingLeft: 30,
    },
    paddingLeft40: {
        paddingLeft: 40,
    },
    paddingLeft50: {
        paddingLeft: 50,
    },
    paddingVertical5: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    paddingVertical20: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    paddingVertical30: {
        paddingTop: 30,
        paddingBottom: 30,
    },
    paddingVertical40: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    paddingVertical50: {
        paddingTop: 50,
        paddingBottom: 50,
    },
    paddingHorizontal0: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    paddingHorizontal5: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    paddingHorizontal10: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    paddingHorizontal20: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    paddingHorizontal30: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    paddingHorizontal40: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    paddingHorizontal50: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    height5: {
        height: 5,
    },
    height10: {
        height: 10,
    },
    height20: {
        height: 20,
    },
    height30: {
        height: 30,
    },
    height40: {
        height: 40,
    },
    height50: {
        height: 50,
    },
    width5: {
        width: 5,
    },
    width10: {
        width: 10,
    },
    width20: {
        width: 20,
    },
    width30: {
        width: 30,
    },
    width40: {
        width: 40,
    },
    width50: {
        width: 50,
    },
    borderRadius5: {
        borderRadius: 5,
    },
    borderRadius10: {
        borderRadius: 10,
    },
    borderRadius20: {
        borderRadius: 20,
    },
    round: {
        borderRadius: 1000,
    },
    opacity60: {
        opacity: 0.6,
    },
    zIndex1: {
        zIndex: 1,
    },
    zIndex2: {
        zIndex: 2,
    },
    zIndex3: {
        zIndex: 3,
    },
    zIndex4: {
        zIndex: 4,
    },
    zIndex5: {
        zIndex: 5,
    },
    zIndex6: {
        zIndex: 6,
    },
    // compund
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
    background: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
};

export default GlobalStyles;
