import React from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, Button, ScrollView, Picker, Text } from 'react-native';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { Colors, Typography } from '../constants';
import { createRole, createGoal } from '../ducks/AgendaDuck';
import { WeekPicker } from '../components';

class CreateAgendaScreen extends React.Component {
    state = {
        roleTitle: '',
        goalDescription: '',
        selectedRole: null,
        activeWeek: moment().clone().startOf('isoWeek'),
    };

    createRolePress = () => {
        this.props.createRole({
            title: this.state.roleTitle,
        });
    };

    createGoalPress = () => {
        const standardizedActiveWeek = `${this.state.activeWeek.format('YYYY-MM-DDT00:00:00')}+00:00`;

        this.props.createGoal({
            role: this.state.selectedRole ? this.state.selectedRole : this.props.roles[0].title,
            description: this.state.goalDescription,
            week: standardizedActiveWeek,
        });
    };

    renderCreateRoleForm = () => {
        return (
            <View style={{ paddingTop: 30 }}>
                <TextInput
                    style={styles.inputBox}
                    value={this.state.roleTitle}
                    onChangeText={(roleTitle) => this.setState({ roleTitle })}
                    placeholder='Role Title'
                />
                <TouchableOpacity style={styles.button} onPress={this.createRolePress}>
                    <Text style={styles.buttonText}>Create Role</Text>
                </TouchableOpacity>
            </View>
        );
    };

    renderCreateGoalForm = () => {
        const { roles } = this.props;
        const { activeWeek, selectedRole, goalDescription } = this.state;
        return (
            <View style={styles.container}>
                {roles?.length > 0 && (
                    <Picker
                        selectedValue={selectedRole}
                        style={{ height: 50, marginHorizontal: 40 }}
                        onValueChange={(itemValue) => this.setState({ selectedRole: itemValue })}
                    >
                        {roles.map((role) => {
                            return <Picker.Item label={role.title} value={role.title} />;
                        })}
                    </Picker>
                )}
                <WeekPicker
                    activeWeek={activeWeek}
                    setActiveWeek={(activeWeek) => {
                        this.setState({ activeWeek });
                    }}
                />
                <TextInput
                    style={styles.inputBox}
                    value={goalDescription}
                    onChangeText={(goalDescription) => this.setState({ goalDescription })}
                    placeholder='Description'
                />
                <TouchableOpacity style={styles.button} onPress={this.createGoalPress} disabled={!roles || !goalDescription}>
                    <Text style={styles.buttonText}>Create Goal</Text>
                </TouchableOpacity>
            </View>
        );
    };

    render() {
        return (
            <ScrollView
                style={{
                    flex: 1,
                    backgroundColor: Colors.mediumChampagne,
                }}
            >
                {this.renderCreateRoleForm()}
                {this.renderCreateGoalForm()}
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'stretch',
        paddingTop: 30,
    },
    inputBox: {
        ...Typography.subtitle1,
        width: '85%',
        margin: 10,
        padding: 15,
        borderColor: Colors.blueSapphire,
        borderBottomWidth: 1,
        textAlign: 'center',
        alignSelf: 'center',
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
});

const mapStateToProps = ({ habits, firestore, firebase }) => {
    const { createRoleSuccess, createGoalSuccess, error, loading } = habits;
    const { ordered } = firestore;
    const { auth } = firebase;

    return {
        auth,
        agenda: ordered.agendas ? ordered.agendas : [],
        roles: ordered.roles ? ordered.roles : [],
        createRoleSuccess,
        createGoalSuccess,
        error,
        loading,
    };
};

export default compose(
    connect(mapStateToProps, {
        createRole,
        createGoal,
    }),
    firestoreConnect((props) => [
        {
            collection: 'goals',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['week', 'desc'],
        },
        {
            collection: 'roles',
            where: ['uid', '==', props.auth.uid],
            orderBy: ['date', 'desc'],
        },
    ])
)(CreateAgendaScreen);
